import Container from '@/components/atoms/Container'

import Icon from '@/components/atoms/Icon'

import SubtitleWithDot from '@/components/atoms/SubtitleWithDot'

import Link from 'next/link'

import {LinkType} from '@/components/types/link'
import styles from './LinkList.module.scss'

type LinkListProps = {
  block: {
    title?: string
    items: {
      link: LinkType
      subtitle: string
      subtitle2: string
    }[]
  }
}

export default function LinkList({block}: LinkListProps) {
  return (
    <Container>
      <div className={styles.linkList}>
        {block.title && <h2 className={styles.title}>{block.title}</h2>}
        <div className={styles.wrapper}>
          {block.items?.map(
            (
              item: {link: LinkType; subtitle: string; subtitle2: string},
              index: number
            ) => (
              <div className={styles.courseWrapper} key={index}>
                <SubtitleWithDot
                  word1={item.subtitle}
                  word2={item.subtitle2}
                  className={styles.subtitleWrapper}
                />
                {item.link && (
                  <Link href={item.link?.url}>
                    <a className={styles.link}>
                      <div
                        dangerouslySetInnerHTML={{__html: item.link?.title}}
                      />
                      <div className={styles.arrowWrapper}>
                        <Icon icon="arrowRight" size={32} sizeViewbox={32} />
                      </div>
                    </a>
                  </Link>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </Container>
  )
}
